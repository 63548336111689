video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
  }

#aboutContainer{
    height: 100vh;
    width: 100%;
    object-fit: contain;
    display: flex;
    justify-content: left;
    align-items: center;
}

div.inside {
    --s:550px;  /*Size of the circle */
    --p:15px;   /* padding */
  
    height: var(--s);
    width: var(--s);
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    color:#fff;
    font-size:26px;
    text-align:center;
  }
  
  .inside i,
  .inside::before {
    content: '';
    float: left;
    height:100%;
    width: 50%;
    shape-outside: radial-gradient(farthest-side at right, transparent calc(100% - var(--p)), #fff 0);
  }
  
  .inside i {
    float: right;
    shape-outside: radial-gradient(farthest-side at left,  transparent calc(100% - var(--p)), #fff 0);
  }


  @media screen and (max-width: 960px) {
    div.inside {
    --s:450px;  /*Size of the circle */
    --p:15px;   /* padding */
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    div.inside {
    --s:400px;  /*Size of the circle */
    --p:15px;   /* padding */
    font-size: 16px;
    }
}