#homePic{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
}

#homeContainer{
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.insideHome{
    color:black;
    font-size:44px;
    font-weight: bold;
    text-align: center;
    padding-top: 100px;
}

@media screen and (max-width: 960px) {
    .insideHome {
      font-size: 22px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .insideHome {
      padding-top: 50px;
      font-size: 16px;
    }
}