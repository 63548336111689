*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Times New Roman', Times, serif;
}

#contBody{
    background: grey;
    display:flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.bigBox{
    position: relative;
    width: 100%;
    max-width: 1000px;
    min-height: 850px;
    background: white;
    display:grid;
    grid-template-columns: 1fr 2fr;
}

.bigBox .leftBox{
    position: relative;
    background: rgb(30, 168, 220);
    padding:40px;
}

.profileText{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    
    background-color: rgb(30, 168, 220);
}

.profileText .imgBox{
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow:hidden;
}

.profileText .imgBox img{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileText h2{
    color: BLACK;
    font-size: 26px;
    margin-top: 20px;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    background-color: rgb(30, 168, 220);
}

.profileText h2 span{
    font-size: 18px;
}

.bigBox .contactInfo .title{
    font-weight: 800;
}
.contactInfo{
    padding-top: 40px;
}

.bigBox .contactInfo .title{
    color: black;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}


.contactInfo ul{
position: relative;

}

.bigBox .contactInfo ul li{
    list-style: none;
    position: relative;
    margin: 10px 0;
    font-weight: 800;
}

.contactInfo ul li .icon{
    display: inline-block;
    width: 30px;
    font-size: 18px;
    color: black;
}

.contactInfo ul li .text{
    font-size: 18px;
}

.bigBox .contactInfo-education .title{
    color: black;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.bigBox .contactInfo-education ul li{
    margin:10px 0;
    list-style: none;
}

.bigBox .contactInfo-education h5{
    color:black;
    font-weight: 600;
    padding-bottom: 5px;
}

.bigBox .contactInfo-education h4{
    font-weight: 800;
}


.bigBox .rightBox{
    position: relative;
    background: rgb(224, 222, 204);
    padding:40px;
}

.about{
    margin-bottom: 50px;
}

.about:last-child{
    margin-bottom: 0;
}

.title2{
    color:black;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.rightBox p{
    font-size: 18px;
    color: black;
}

.about-skills .box{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;
    justify-content: center;
    align-items: center;
}

.about-skills .box h4{
    color: gray;
    font-weight: 500;
}

.about-skills .box .percent{
    position: relative;
    width: 100%;
    height: 10px;
    background: lightgrey;
}

.box .percent div{
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    background: rgb(30, 168, 220);
}

.projects{
    margin-top: 50px;
}

.title3{
    color:black;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.title3 a{
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    color: black;
}

.projects .box{
    display:flex;
    flex-direction: row;
    margin:30px 0;
}

.projects .box h4{
    min-width: 100px;
    color:gray;
    width: 50%;
    font-weight: 500;
}

.projects .box p{
    margin:0 80px;
    letter-spacing: .5px;
    font-size: 14px;
    color:black;
}




@media screen and (max-width: 960px) {
    .bigBox
    {
    max-width: 768px;
    min-height: 700px;
    }

    .bigBox .leftBox
    {
    padding:20px;
    }

    .profileText
    {
    padding-bottom:10px;
    }

    .profileText .imgBox 
    {
        width: 120px;
        height: 120px;
    }

    .profileText h2
    {
        font-size: 16px;
    }

    .profileText h2 span
    {
        font-size: 15px;
    }

    .contactInfo
    {
        padding-top: 20px;
    }

    .bigBox .contactInfo .title
    {
        font-weight: 450;
        margin-bottom: 10px;
    }

    .bigBox .contactInfo ul li
    {
        margin: 5px 0;
        font-weight: 700;
    }

    .contactInfo ul li .icon
    {
        width: 20px;
        font-size: 14px;
    }

    .contactInfo ul li .text
    {
        font-size: 14px;
    }

    .bigBox .contactInfo-education .title
    {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 300;
        text-align: center;
    }

    .bigBox .contactInfo-education h5
    {
        font-weight: 400;
        padding-bottom: 5px;
    }

    .bigBox .contactInfo-education h4
    {
        font-weight: 500;
    }

    .bigBox .contactInfo-education h3
    {
        font-weight: 600;
        font-size: 16px;
    }

    .about
    {
        padding-bottom: 10px;
    }

    .bigBox .rightBox
    {
        padding:30px;
    }

    .title2
    {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0px;
        font-size: 16px;
    }

    .rightBox p
    {
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .about
    {
        margin-bottom: 15px;
    }

    .about-skills .title2
    {
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .about-skills .box
    {
        grid-template-columns: 100px 1fr;
    }

    .about-skills .box h4
    {
        font-size: 14px;
        font-weight: 400;
    }

    .title3
    {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0px;
        font-size: 16px;
    }
    .title3 a
    {
        display: inline-block;
        font-size: 14px;
        padding-left: 20px;
    }

    .projects
    {
        margin-top: 20px;
    }

    .projects .box
    {
        margin:10px 0;
    }

    .projects .box h4
    {
        font-size: 14px ;
        min-width: 60px;
    }

    .projects .box p
    {
        margin:0 60px;
        font-size: 12px;
    }

  }



@media screen and (max-width: 767px) {
    
    .bigBox
    {
    max-width: 580px;
    min-height: 820px;
    }

    .bigBox .leftBox
    {
        padding:2px;
    }

    .profileText
    {
        padding-bottom:5px;
    }

    .profileText .imgBox 
    {
        width: 90px;
        height: 90px;
    }

    .profileText h2
    {
        font-size: 12px;
    }

    .profileText h2 span
    {
        font-size: 11px;
    }

    .contactInfo
    {
        padding-top: 10px;
    }

    .bigBox .contactInfo .title
    {
        text-align: center;
        font-size: 14px;
        font-weight: 200;
        margin-bottom: 5px;
    }

    .bigBox .contactInfo ul li
    {
        font-size: 12px;
        margin:0;
        font-weight: 600;
    }

    .contactInfo ul li .icon
    {
        display: block;
        width: 10px;
        font-size: 10px;
    }

    .contactInfo ul li .text
    {
        font-size: 10px;
    }

    .bigBox .contactInfo-education .title
    {
        margin-top: 20px;
        margin-bottom: 5px;
        font-weight: 200;
        font-size: 14px;
    }

    .bigBox .contactInfo-education h5
    {
        font-weight: 300;
        padding-bottom: 5px;
    }

    .bigBox .contactInfo-education h4
    {
        font-size: 12px;
        font-weight: 400;
    }

    .bigBox .contactInfo-education h3
    {
        font-weight: 400;
        font-size: 14px;
    }

    .about
    {
        padding-bottom: 5px;
    }

    .bigBox .rightBox
    {
        padding:10px;
    }

    .title2
    {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 14px;
    }

    .rightBox p
    {
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .about
    {
        margin-bottom: 10px;
    }

    .about-skills .title2
    {
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .about-skills .box
    {
        grid-template-columns: 50px 1fr;
    }

    .about-skills .box h4
    {
        font-size: 12px;
        font-weight: 400;
    }

    .title3
    {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 14px;
    }

    .title3 a
    {
        display: inline-block;
        font-size: 12px;
        padding-left: 20px;
    }

    .projects
    {
        margin-top: 10px;
    }

    .projects .box
    {
        display: block;
        margin:5px 0;
    }

    .projects .box h4
    {
        text-align: center;
        font-size: 12px ;
        min-width: 40px;
        font-weight: 550;
    }

    .projects .box p
    {
        margin:0 0px;
        font-size: 10px;
    }

}